import React from 'react';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useThemeConfig } from 'common/hooks';
import { capfl } from 'common/utils';
import Tag from 'common/components/styleguide/Tag';

const S = {};

S.Stickers = styled.div`
  position: absolute;
  top: 5px;
  left: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: auto;
  z-index: ${({ theme }) => _.get(theme, 'zIndex.sticker', 1)};
`;

S.Tag = styled(Tag)`
  && {
    padding: ${({ photo }) => photo ? '0' : '0 20px'};
    position: relative;
    left: 0;
    margin-bottom: 5px;
  }
`;

const Sticker = ({ t, product }) => {
  const {
    favourite,
    status,
    discount,
    new: isNew = false,
    labels = [],
  } = product;
  const themeConfig = useThemeConfig();
  return (
    <S.Stickers>
      {discount && _.get(themeConfig, 'labels.discount.active', true) ? <S.Tag color={'discount'} name={capfl(t('discount'))} /> : null}
      {isNew && _.get(themeConfig, 'labels.new.active', true) ? <S.Tag color={'new'} name={capfl(t('new'))} /> : null}
      {favourite && _.get(themeConfig, 'labels.favourite.active', true) ? <S.Tag color={'favourite'} name={capfl(t('favourite'))} /> : null}
      {status && _.get(themeConfig, 'labels.status.active', true) ? <S.Tag color={'status'} name={status} /> : null}
      {!_.isEmpty(labels)
        ? _.map(_.orderBy(labels, 'priority', 'desc'), (label) => {
            return (
              <S.Tag
                color={'status'}
                backgroundColor={_.get(label, 'backgroundColor')}
                textColor={_.get(label, 'textColor')}
                photo={_.get(label, 'photo')}
                name={_.get(label, 'name')}
              />
            );
          })
        : null}
    </S.Stickers>
  );
};

export default withTranslation()(Sticker);
