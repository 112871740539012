import React, { PureComponent } from 'react';
import styled, { css } from 'styled-components';
import _ from 'lodash';

const DEFAULT_INPUT_COLOR = '#495057';
const DEFAULT_INPUT_PADDING = '0.45em 0.8em';
const DEFAULT_INPUT_FONT_SIZE = '1em';
const DEFAULT_INPUT_HEIGHT = '40px';
const DEFAULT_INPUT_LINE_HEIGHT = '1.5';


const StyledWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  background-clip: padding-box;
  position: relative;
  &:after {
    content: '\u21F5';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    padding-right: 10px;
    font-size: 15px;
  }
`;

const StyledSelect = styled.select`
  width: 100%;
  box-sizing: border-box;
  background-clip: padding-box;
  display: inline-block;
  font-size: ${({ theme }) => _.get(theme, 'input.fontSize', DEFAULT_INPUT_FONT_SIZE)};
  line-height: ${({ customStyle }) => _.get(customStyle, 'lineHeight', DEFAULT_INPUT_LINE_HEIGHT)};
  color: ${({ theme }) => _.get(theme, 'input.color', DEFAULT_INPUT_COLOR)};
  padding: ${({ theme, inputSize }) =>
    _.get(theme, ['input', 'padding', inputSize], DEFAULT_INPUT_PADDING)};
  padding-right: 25px;
  min-height: ${({ theme, inputSize }) =>
    _.get(theme, ['input', 'height', inputSize], DEFAULT_INPUT_HEIGHT)};

  border-radius: ${({ theme }) => _.get(theme, 'selectbox.borderRadius', 0)};
  border: ${({ theme }) => _.get(theme, 'input.border', _.get(theme, 'border.default.width') + ' ' + _.get(theme, 'border.default.style'))};
  border-color: ${({ theme }) => _.get(theme, 'input.borderColor', _.get(theme, 'border.default.color'))};
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: 0;
  box-shadow: none;
  background-image: none;
  background: ${({ theme }) => _.get(theme, 'input.background', _.get(theme, 'colors.background'))};
  transition: ${({ theme }) => _.get(theme, 'selectbox.transition', '')};
  &:hover {
    ${({ theme }) => _.get(theme, 'selectbox.state.hover', null)}
  }
  &:active {
    ${({ theme }) => _.get(theme, 'selectbox.state.active', null)}
  }
  &:focus {
    ${({ theme }) => _.get(theme, 'selectbox.state.focus', null)}
  }
  &:-ms-expand {
    display: none;
  }
  ${({ error }) =>
    error &&
    css`
      border-color: ${({ theme }) => _.get(theme, 'colors.danger')};
      color: ${({ theme }) => _.get(theme, 'colors.danger')};
      &:hover {
        border-color: ${({ theme }) => _.get(theme, 'colors.danger')};
        color: ${({ theme }) => _.get(theme, 'colors.danger')};
      }
    `}
  ${({ customStyle }) => {
    return css`
      ${customStyle}
    `;
  }}
`;

const StyledOption = styled.option`
  line-height: ${({ customStyle }) => _.get(customStyle, 'lineHeight', DEFAULT_INPUT_LINE_HEIGHT)};
`;

class SelectCustom extends PureComponent {
  state = {
    selectedOption: ''
  };
  handleChange = e => {
    const { name, onChange } = this.props;
    const value = e.target.value;
    this.setState({ selectedOption: value });
    if (onChange) {
      onChange(value);
    }
  };
  handleBlur = () => {
    const { onBlur } = this.props;
    if (onBlur) {
      onBlur();
    }
  };
  handleClear = () => {
    this.setState({ selectedOption: '' });
  };
  static getDerivedStateFromProps(props, state) {
    const { defaultValue } = props;
    if (defaultValue) {
      return { ...state, ...{ selectedOption: defaultValue } };
    }
    return state;
  }
  render() {
    const {
      error,
      className,
      size = 'normal',
      options,
      customStyle,
      customStyleWrapper,
      placeholder,
      name,
      prompt = {}
    } = this.props;
    const { selectedOption } = this.state;
    const classNames = [];
    if (className) {
      classNames.push(className);
    }
    return (
      <StyledWrapper
        error={error}
        customStyleWrapper={customStyleWrapper}
        className={classNames.join(' ')}
      >
        <StyledSelect
          name={name}
          inputSize={size}
          value={selectedOption}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          error={error}
          customStyle={customStyle}
          placeholder={placeholder}
        >
          {!_.isEmpty(prompt) ? (
            <option key={'select-option-' + prompt.value} value={prompt.value}>
              {prompt.label}
            </option>
          ) : (
            ''
          )}
          {_.map(options, option => {
            return (
              <StyledOption key={'select-option-' + option.value} value={option.value}>
                {option.label}
              </StyledOption>
            );
          })}
        </StyledSelect>
      </StyledWrapper>
    );
  }
}

export default SelectCustom;
