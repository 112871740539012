import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';

import ButtonOutline from 'common/components/styleguide/ButtonOutline';
import Icon from 'common/components/styleguide/Icon';
import TextIcon from 'common/components/styleguide/TextIcon';

import {addProductToWishlist} from 'common/actions/UserActions';
import {togglePageModal} from 'common/actions/PageActions';

import { ICON_WISHLIST } from 'common/constants/IconConstants';
import {isUserLoggedSelector} from 'common/selectors/user';
import {isWishListEnabledSelector} from 'common/selectors/theme';
import {capfl} from 'common/utils/index';

const addToWishlist = ({
  t,
  productId,
  title = 'add to wishlist',
  iconClassName = ICON_WISHLIST,
  dispatchAddProductToWishlist,
  dispatchShowPageModal,
  customStyle,
  linkType = 'primary',
  isLogged = false,
  isEnabled = false,
  ...rest
}) => {
  if (!isEnabled) {
    return null;
  }
  return (
    <ButtonOutline
      href="#"
      component={'a'}
      buttonType={'info'}
      buttonSize={'small'}
      margin={'2px'}
      enableBackground={true}
      onClick={e => {
        e.preventDefault();
        if (isLogged) {
          dispatchAddProductToWishlist(productId);
        } else {
          dispatchShowPageModal('nologged_wishlist');
        }
      }}
      customStyle={_.get(customStyle, 'link', undefined)}
      linkType={linkType}
      {...rest}
    >
      {title ? (
        <TextIcon iconClassName={iconClassName}>
          {capfl(t(title))}
        </TextIcon>
      ) : (
        <Icon className={iconClassName} />
      )}
    </ButtonOutline>
  );
};

const makeMapStateToProps = () => {
  const isUserLogged = isUserLoggedSelector();
  const isWishListEnabled = isWishListEnabledSelector();
  return (state, props) => {
    return {
      isLogged: isUserLogged(state, props),
      isEnabled: isWishListEnabled(state, props)
    };
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatchAddProductToWishlist: productId => {
      dispatch(addProductToWishlist(productId));
    },
    dispatchShowPageModal: modalId => {
      dispatch(togglePageModal(modalId, true));
    }
  };
};

addToWishlist.propTypes = {
  productId: PropTypes.number.isRequired
};

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(addToWishlist);
