import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  getActiveThemeSelector,
  getLanguageSelector
} from '../../selectors/index';
import NormalLink from '../styleguide/Link';

import { setRouteParams } from '../../utils/index';

const RouteLink = ({
  children,
  activeTheme,
  route = '',
  params = {},
  currentLang = '',
  toPathOptions = {},
  to,
  href = '',
  ...rest
}) => {
  if (to) {
    return <Link to={to}>{children}</Link>;
  }
  const resultRoute = setRouteParams(
    route,
    params,
    currentLang,
    activeTheme,
    toPathOptions
  );
  return (
    <NormalLink href={href ? href : resultRoute} {...rest}>
      {children}
    </NormalLink>
  );
};

const makeMapStateToProps = () => {
  const getLanguage = getLanguageSelector();
  const getActiveTheme = getActiveThemeSelector();
  return (state, props) => {
    return {
      currentLang: getLanguage(state, props),
      activeTheme: getActiveTheme(state, props)
    };
  };
};

export default connect(makeMapStateToProps)(RouteLink);
