import React from 'react';
import styled, { css } from 'styled-components';
import _ from 'lodash';
import loadable from '@loadable/component';

const Image = loadable(() => import('common/components/_base/Image'));

const S = {};
S.Tag = styled.div`
  display: inline-flex;
  border-radius: ${({ theme }) => _.get(theme, 'border.components.radius')};
  color: ${({ theme, color, textColor = '' }) =>
    textColor ||
    _.get(
      theme,
      ['labels', color, 'color'],
      '#ffffff'
  )};
  border: ${({ theme, color }) =>
    _.get(
      theme,
      ['labels', color, 'border'],
      'none'
    )};
  padding: ${({ padding }) => (padding ? padding : '0 10px')};
  min-height: 20px;
  line-height: 20px;
  font-size: 0.7rem;
  text-align: center;
  align-items: center;
  background: ${({ theme, color, backgroundColor = '' }) =>
    backgroundColor ||
    _.get(
      theme,
      ['labels', color, 'background'],
      _.get(theme, ['colors', color],
        _.get(theme, 'colors.primary'))
    )};

  ${({ backgroundColor }) =>
      backgroundColor && css`
        border-color: ${backgroundColor};
      `
}
`;

S.Image = styled(Image)`
  display: flex;
  max-width: 60px;
  height: auto;
`;

const Tag = ({ name, color = 'primary', textColor = '', backgroundColor = '', photo = null,  ...rest }) => {
  const className = 'tag';
  if (!_.isEmpty(photo)) {
    return (
      <S.Tag className={className} color="transparent" textColor="transparent" backgroundColor="transparent" padding="0px" {...rest}>
        <S.Image {...photo} lazyload={false} size="60__cropped.png" />
      </S.Tag>
    );
  }
  return (
    <S.Tag className={className} color={color} textColor={textColor} backgroundColor={backgroundColor} {...rest}>
      {name}
    </S.Tag>
  );
};

export default React.memo(Tag);
