import React from 'react';
import styled, { css } from 'styled-components';
import _ from 'lodash';
import { sprintf } from 'sprintf-js'

import { withCssUnit } from 'common/components/styleguide/utils';

const StyledFinalPrice = styled.div`
  white-space: nowrap;
  font-family: ${({ theme, customStyle }) =>
    _.get(
      customStyle,
      'finalPrice.fontFamily',
      _.get(theme, 'price.fontFamily', _.get(theme, 'fontFamily', 'inherit'))
    )};
  color: ${({ theme, customStyle }) =>
    _.get(
      customStyle,
      'finalPrice.color',
      _.get(theme, 'price.finalPrice.color', _.get(theme, 'colors.text'))
    )};
  font-weight: ${({ theme, customStyle }) =>
    _.get(
      customStyle,
      'finalPrice.fontWeight',
      _.get(theme, 'price.finalPrice.fontWeight', 'normal')
    )};
  text-decoration: ${({ theme, customStyle }) =>
    _.get(
      customStyle,
      'finalPrice.textDecoration',
      _.get(theme, 'price.finalPrice.textDecoration', 'none')
    )};
  text-align: ${({ theme, customStyle }) =>
    _.get(
      customStyle,
      'finalPrice.textAlign',
      _.get(theme, 'price.finalPrice.textAlign', 'left')
    )};
  font-size: ${({ theme, customStyle }) =>
    _.get(
      customStyle,
      'finalPrice.fontSize',
      _.get(theme, 'price.finalPrice.fontSize')
    )};
  white-space: nowrap;
`;

const StyledFinalPriceWithoutVAT = styled.div`
  white-space: nowrap;
  font-family: ${({ theme, customStyle }) =>
    _.get(
      customStyle,
      'finalPriceWithoutVAT.fontFamily',
      _.get(theme, 'price.fontFamily', _.get(theme, 'fontFamily', 'inherit'))
    )};
  color: ${({ theme, customStyle }) =>
    _.get(
      customStyle,
      'finalPriceWithoutVAT.color',
      _.get(
        theme,
        'price.finalPriceWithoutVAT.color',
        _.get(theme, 'colors.gray')
      )
    )};
  font-weight: ${({ theme, customStyle }) =>
    _.get(
      customStyle,
      'finalPriceWithoutVAT.fontWeight',
      _.get(theme, 'price.finalPriceWithoutVAT.fontWeight', 'lighter')
    )};
  text-decoration: ${({ theme, customStyle }) =>
    _.get(
      customStyle,
      'finalPriceWithoutVAT.textDecoration',
      _.get(theme, 'price.finalPriceWithoutVAT.textDecoration', 'none')
    )};
  font-size: ${({ theme, customStyle }) =>
    _.get(
      customStyle,
      'finalPriceWithoutVAT.fontSize',
      _.get(theme, 'price.finalPriceWithoutVAT.fontSize', '0.7em')
    )};
`;

const StyledDiscount = styled.sup`
  font-family: ${({ theme, customStyle }) =>
    _.get(
      customStyle,
      'discount.fontFamily',
      _.get(theme, 'price.fontFamily', _.get(theme, 'fontFamily', 'inherit'))
    )};
  color: ${({ theme, customStyle }) =>
    _.get(
      customStyle,
      'discount.color',
      _.get(theme, 'price.discount.color', _.get(theme, 'colors.danger'))
    )};
  font-weight: ${({ theme, customStyle }) =>
    _.get(
      customStyle,
      'discount.fontWeight',
      _.get(theme, 'price.discount.fontWeight', 500)
    )};
  font-size: ${({ theme, customStyle }) =>
    _.get(
      customStyle,
      'discount.fontSize',
      _.get(theme, 'price.discount.fontSize', '.75em')
    )};
  text-decoration: ${({ theme, customStyle }) =>
    _.get(
      customStyle,
      'discount.textDecoration',
      _.get(theme, 'price.discount.textDecoration', 'none')
    )};
  margin-right: 2px;
  white-space: nowrap;
`;

const StyledPriceOld = styled.span`
  font-family: ${({ theme, customStyle }) =>
    _.get(
      customStyle,
      'price.fontFamily',
      _.get(theme, 'price.fontFamily', _.get(theme, 'fontFamily', 'inherit'))
    )};
  color: ${({ theme, customStyle }) =>
    _.get(
      customStyle,
      'price.color',
      _.get(theme, 'price.price.color', _.get(theme, 'colors.lgray'))
    )};
  font-weight: ${({ theme, customStyle }) =>
    _.get(
      customStyle,
      'price.fontWeight',
      _.get(theme, 'price.price.fontWeight', 500)
    )};
  font-size: ${({ theme, customStyle }) =>
    _.get(
      customStyle,
      'price.fontSize',
      _.get(theme, 'price.price.fontSize', '.75em')
    )};
  position: relative;
  white-space: nowrap;
  &:before {
    content: '';
    width: 100%;
    position: absolute;
    right: -2px;
    top: 50%;
    border-bottom: ${({ theme, customStyle }) =>
      _.get(
        customStyle,
        'price.border',
        _.get(theme, 'price.price.border', '1px solid #666')
      )};
    transform: skewY(-10deg);
  }
  ${({ theme, customStyle }) => _.get(customStyle, 'priceOld.customStyle')};
`;

const StyledPriceOuter = styled.div`
  display: ${({ isInline }) => (isInline ? 'inline-flex' : 'inline-block')};
  ${({ isInline }) =>
    isInline &&
    css`
      align-items: baseline;
      flex-direction: column;

      @media (min-width: ${({ theme }) => withCssUnit(theme.breakpoints.md)}) {
        flex-direction: row;
      }
    `}
`;

const StyledPriceWrapper = styled.div`
  display: flex;
  align-items: flex-end;

  ${({ isInline }) =>
    isInline &&
    css`
      margin-right: 10px;
    `}
`;

const JSXFinalPrice = ({
  price,
  currencySymbol,
  discount,
  measureUnit = false,
  suffix = '',
  customStyle = {}
}) => {
  return (
    <StyledFinalPrice customStyle={customStyle}>
      {price} {currencySymbol} {measureUnit ? ' / ' + measureUnit : ''} {suffix}{' '}
      {discount}
    </StyledFinalPrice>
  );
};

const JSXVatPrice = ({
  price,
  currencySymbol,
  measureUnit = false,
  suffix = '',
  customStyle = {},
  withoutVatPrefix = '',
  withoutVatSuffix = ''
}) => {
  return (
    <StyledFinalPriceWithoutVAT customStyle={customStyle}>
      {withoutVatPrefix} {price} {currencySymbol}{' '}
      {measureUnit ? ' / ' + measureUnit : ''} {suffix} {withoutVatSuffix}
    </StyledFinalPriceWithoutVAT>
  );
};

const JSXDiscount = ({ discount, showDiscount = true, customStyle = {} }) => {
  if (!showDiscount || !discount) {
    return null;
  }
  return <StyledDiscount customStyle={customStyle}>{discount}</StyledDiscount>;
};

const Price = ({
  className,
  showDiscount = false,
  showWithoutVat = true,
  showPriceOld = true,
  showPrimaryText = false,
  finalPrice,
  finalPriceWithoutDPH,
  price,
  discount,
  currencySymbol = '',
  withoutVatText = 'without VAT',
  withVatText = 'with VAT',
  withoutVatPrefix = '',
  withoutVatSuffix = '',
  measureUnit = false,
  soloFinalPrice = false,
  suffixOnNewLine = false,
  soloPriceSuffix = '',
  isB2B = false,
  customStyle,
  isInline = false,
  priceData = null,
  finalPriceData = null
}) => {
  const classNames = [];
  if (className) {
    classNames.push(className);
  }

  if (soloFinalPrice) {
    return (
      <StyledPriceOuter className={classNames.join(' ')}>
        <StyledPriceWrapper>
          <StyledFinalPrice customStyle={customStyle}>
            {_.get(finalPriceData, 'priceFormatted', sprintf("%1$s %2$s", finalPrice, currencySymbol))}
            {measureUnit ? ' / ' + measureUnit : ''} {suffixOnNewLine ? <br /> : ''}{' '}
            {soloPriceSuffix ? <span>{soloPriceSuffix}</span> : ''}
          </StyledFinalPrice>
        </StyledPriceWrapper>
      </StyledPriceOuter>
    );
  }

  if (isB2B) {
    return (
      <StyledPriceOuter isInline={isInline} className={classNames.join(' ')}>
        <StyledPriceWrapper isInline={isInline}>
          <JSXFinalPrice
            price={_.get(finalPriceData, 'priceWithoutVatFormatted', sprintf("%1$s %2$s", finalPriceWithoutDPH, currencySymbol))}
            currencySymbol={null}
            measureUnit={measureUnit}
            discount={
              <JSXDiscount
                discount={discount}
                showDiscount={showDiscount}
                customStyle={customStyle}
              />
            }
            suffix={showPrimaryText ? withoutVatText : false}
            customStyle={customStyle}
          />
        </StyledPriceWrapper>
        {finalPrice && showWithoutVat ? (
          <JSXVatPrice
            price={_.get(finalPriceData, 'priceFormatted', sprintf("%1$s %2$s", finalPrice, currencySymbol))}
            currencySymbol={null}
            measureUnit={measureUnit}
            suffix={withVatText}
            customStyle={customStyle}
            withoutVatPrefix={withoutVatPrefix}
            withoutVatSuffix={withoutVatSuffix}
          />
        ) : (
          ''
        )}
      </StyledPriceOuter>
    );
  }
  return (
    <StyledPriceOuter isInline={isInline} className={classNames.join(' ')}>
      <StyledPriceWrapper isInline={isInline}>
        <JSXFinalPrice
          price={_.get(finalPriceData, 'priceFormatted', sprintf("%1$s %2$s", finalPrice, currencySymbol))}
          currencySymbol={null}
          measureUnit={measureUnit}
          discount={
            <JSXDiscount
              discount={discount}
              showDiscount={showDiscount}
              customStyle={customStyle}
            />
          }
          suffix={showPrimaryText ? withVatText : false}
          customStyle={customStyle}
        />
        {showPriceOld && ( (price && finalPrice && price !== finalPrice) || (priceData && finalPriceData && _.get(priceData, 'price') !== _.get(finalPriceData, 'price')) ) ? (
          <StyledPriceOld customStyle={customStyle}>
            {_.get(priceData, 'priceFormatted', sprintf("%1$s %2$s", price, currencySymbol))}
          </StyledPriceOld>
        ) : (
          ''
        )}
      </StyledPriceWrapper>
      {showWithoutVat && finalPriceWithoutDPH ? (
        <JSXVatPrice
          price={_.get(finalPriceData, 'priceWithoutVatFormatted', sprintf("%1$s %2$s", finalPriceWithoutDPH, currencySymbol))}
          currencySymbol={null}
          measureUnit={measureUnit}
          suffix={withoutVatText}
          customStyle={customStyle}
          withoutVatPrefix={withoutVatPrefix}
          withoutVatSuffix={withoutVatSuffix}
        />
      ) : (
        ''
      )}
    </StyledPriceOuter>
  );
};

export default React.memo(Price);
