import React, { Fragment, PureComponent } from 'react';
import _ from 'lodash';

import styled, { css } from 'styled-components';

import AddToCompare from 'common/components/_base/AddToCompare';
import AddToWishList from 'common/components/_base/AddToWishList';
import SelectCustom from 'common/components/styleguide/form/SelectCustom';

import AddToCart from 'common/blocks/functional/addToCartButton';

import Rating from 'common/components/styleguide/Rating';
import {
  Row,
  Column,
  Block,
  BlockBordered,
  BlockFlex
} from 'common/components/styleguide/grid';

import Price from 'common/components/_base/Price';
import Image from 'common/components/_base/Image';
import RouteLink from 'common/components/_base/RouteLink';

import Sticker from 'common/components/_partials/sticker/_default/index.js';
import Availability from 'common/components/_partials/availability/_default/index';

import { withCssUnit } from 'common/components/styleguide/utils';
import { capfl, getImagePath } from 'common/utils';

import { LISTING_LAYOUT_ROWS } from 'common/constants/SiteConstants';

const COLOR_WHITE = '#fff';

const DEFAULT_BOX_SHADOW = 'box-shadow: 0 0 20px rgba(0,0,0,.2);';
const DEFAULT_WIDTH = '100%';

const S = {};

S.ProductCard = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  transition: ${({ theme }) => _.get(theme, 'productCard.transition', 'none')};
  border-radius: ${({ theme }) =>
    _.get(theme, 'productCard.borderRadius', '0px')};
  width: ${({ theme, width }) =>
    !_.isNil(width) ? width : _.get(theme, 'productCard.width', DEFAULT_WIDTH)};
  background: ${({ theme }) =>
    _.get(theme, 'productCard.background', COLOR_WHITE)};
  border: ${({ theme }) => _.get(theme, 'productCard.border', 'none')};
  &:hover {
    background: ${({ theme }) =>
      _.get(theme, 'productCard.hover.background', 'transparent')};
    border: ${({ theme }) => _.get(theme, 'productCard.hover.border', 'none')};
    box-shadow: ${({ theme, hasBoxShadow }) =>
      hasBoxShadow
        ? _.get(theme, 'productCard.hover.boxShadow', DEFAULT_BOX_SHADOW)
        : null};
  }
  ${({ inline }) =>
    inline &&
    css`
      border: ${({ theme }) => _.get(theme, 'border.default.width')}
        ${({ theme }) => _.get(theme, 'border.default.style')}
        ${({ theme }) => _.get(theme, 'border.default.color')};
      margin-bottom: 5px;
    `}
`;

S.ProductCardInner = styled.div`
  position: relative;
  ${({ inline }) =>
    inline &&
    css`
      display: flex;
      flex-wrap: wrap;
    `}
`;

S.Top = styled(({ isBig, children, urlTitle, ...props }) => {
  if (!_.isEmpty(urlTitle) && _.isString(urlTitle)) {
    return (
      <RouteLink route="product" params={{ id: urlTitle }} {...props}>
        {children}
      </RouteLink>
    );
  }
  return <div {...props}>{children}</div>;
})`
  min-height: 70px;
  text-decoration: none;
  display: block;
  padding: 10px;
`;

S.Brand = styled.div`
  color: ${({ theme }) => _.get(theme, 'productCard.brand.color', '#eee')};
  border: 1px solid
    ${({ theme }) => _.get(theme, 'productCard.brand.borderColor', '#eee')};
  font-size: 0.7em;
  font-weight: normal;
  line-height: 1;
  margin-bottom: 5px;
  display: inline-block;
  padding: 2px 5px;
`;

S.ProductName = styled(({ isBig, children, urlTitle, inline, ...props }) => {
  if (!_.isEmpty(urlTitle) && _.isString(urlTitle)) {
    return (
      <RouteLink route="product" params={{ id: urlTitle }} {...props}>
        {children}
      </RouteLink>
    );
  }
  return <div {...props}>{children}</div>;
})`
  font-size: ${({ nameLength, isBig }) => {
    let fontSize;
    if (nameLength > 60) {
      fontSize = isBig ? '0.9em' : '0.6em';
    } else {
      fontSize = isBig ? '1.2em' : '0.8em';
    }
    return fontSize;
  }};

  ${({ theme }) =>
    _.get(theme, 'productCard.name.fontSize')
      ? `font-size: ${_.get(theme, 'productCard.name.fontSize')};`
      : ''}
  ${({ theme }) =>
    _.get(theme, 'productCard.name.lineHeight')
      ? `line-height: ${_.get(theme, 'productCard.name.lineHeight')};`
      : 'line-height: 1.5em;'}
  ${({ theme }) =>
    _.get(theme, 'productCard.name.minHeight')
      ? `min-height: ${_.get(theme, 'productCard.name.minHeight')};`
      : 'min-height: 60px;'}
  ${({ theme }) =>
    _.get(theme, 'productCard.name.fontWeight')
      ? `font-weight: ${_.get(theme, 'productCard.name.fontWeight')};`
      : 'font-weight: bold;'}

  margin: 0;
  color: ${({ theme }) => _.get(theme, 'productCard.name.color')};
  display: flex;
  &:hover {
    color: ${({ theme }) => _.get(theme, 'productCard.name.color')};
  };
  @media (max-width: ${({ theme }) => withCssUnit(theme.breakpoints.md)}) {
    font-size: 0.7em;
    min-height: 50px;
    line-height: 1.4em;
  }
  ${({ inline }) =>
    inline &&
    css`
      font-size: 1.1em;
      font-weight: normal;
      height: auto;
      min-height: auto;
    `}
`;

S.Availability = styled.div`
  font-size: 0.75em;
  padding-bottom: 10px;
`;

S.ImageLink = styled(({ isBig, children, urlTitle, ...props }) => {
  if (!_.isEmpty(urlTitle) && _.isString(urlTitle)) {
    return (
      <RouteLink route="product" params={{ id: urlTitle }} {...props}>
        {children}
      </RouteLink>
    );
  }
  return <div {...props}>{children}</div>;
})`
  position: relative;
  display: flex;
  align-items: center;
`;

S.Image = styled(Image)`
  width: 100%;
`;

S.ImageWrapper = styled.div`
  margin: 0 0 10px 0;
  position: relative;
  background: ${({ theme }) =>
    _.get(theme, 'productCard.image.background', 'transparent')};
  width: ${({ imageWidth }) => (imageWidth ? withCssUnit(imageWidth) : 'auto')};
  ${({ inline }) =>
    inline &&
    css`
      margin: 0 20px 0 0;
    `}
`;

S.UserActions = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  align-items: flex-end;
  top: 0;
  right: 0;
  z-index: 1;
  ${({ inline }) =>
    inline &&
    css`
      flex-direction: row;
    `}
`;

S.AddToCompareInline = styled(AddToCompare)`
  margin-right: 5px;
  margin-left: 5px;
`;

S.Between = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-end;
  @media (max-width: ${({ theme }) => withCssUnit(theme.breakpoints.md)}) {
    flex-direction: column;
  }
`;

S.BrandWithRating = styled.div`
  display: flex;
  justify-content: space-between;
`;

S.InlineInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
`;

S.InlineAddToCart = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

S.InlinePrice = styled(Price)`
  margin-right: 10px;
  display: flex;
  flex-direction: column;
`;

S.AddToCartWrapperInline = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

S.AddToCartWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ActionStyles = (theme, inline = false) => {
  const styles = {
    link: `
    text-decoration: none;
    background: ${_.get(theme, 'page.backgroundSecondary')};
    margin-bottom: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    color: ${_.get(theme, 'colors.text')};
    &:hover {
      color: ${_.get(theme, 'colors.primary')};
    }
    .icon {
      font-size: 16px;
      color: ${_.get(theme, 'colors.text')};
      &:hover {
        color: ${_.get(theme, 'colors.primary')};
      }
    }`
  };
  return styles;
};

const StyledAddToCartVariant = styled.section`
  display: flex;
  flex-direction: column;
`;

const StyledAddToCartVariantAddtoCartWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
`;

const StyledShortDesc = styled.div`
  color: ${({ theme }) => _.get(theme, 'colors.text')};
  font-weight: normal;
  font-size: 0.55em;
  line-height: 1.50em;
  height: 75px;
  overflow-y: auto;
  overflow-x: none;
  ::-webkit-scrollbar {
    width: 1px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => _.get(theme, 'border.default.color')};
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => _.get(theme, 'border.default.color')};
  }
`;

class AddToCartVariant extends PureComponent {
  state = {
    selectedProduct: null
  };
  onChange = productId => {
    const { variants } = this.props;
    const productVariant = _.find(
      variants,
      variant => _.get(variant, 'product.id') === _.toNumber(productId)
    );
    const product = _.get(productVariant, 'product', {});
    if (!_.isEmpty(product)) {
      this.setState({
        selectedProduct: { ...{ isOrderable: true }, ...product }
      });
    } else {
      this.setState({
        selectedProduct: null
      });
    }
  };
  render() {
    const { selectedProduct } = this.state;
    const { t, productVariantsWithValues } = this.props;
    return (
      <StyledAddToCartVariant>
        <SelectCustom
          customStyleWrapper={{
            height: '38px',
            lineHeight: '38px'
          }}
          customStyle={{
            minHeight: '38px',
            lineHeight: 1
          }}
          name="productVariant"
          options={[].concat(
            [{ label: capfl(t('choose variant')), value: 0 }],
            _.map(productVariantsWithValues, item => {
              const { values, productId } = item;
              return {
                label: _.map(values, item => {
                  return item.name + ' - ' + item.value;
                }).join(', '),
                value: productId
              };
            })
          )}
          onChange={this.onChange}
        />
        {selectedProduct ? (
          <StyledAddToCartVariantAddtoCartWrapper>
            <Price
              isB2B={true}
              className="product-card__price"
              useFormatPrice={false}
              finalPriceData={selectedProduct.finalPriceData}
            />
            <AddToCart
              buttonSize={'small'}
              title=""
              t={t}
              product={selectedProduct}
            />
          </StyledAddToCartVariantAddtoCartWrapper>
        ) : (
          ''
        )}
      </StyledAddToCartVariant>
    );
  }
}

S.AddToCartVariantInlinePrice = styled(Price)`
  margin-left: 10px;
`;
S.AddToCartVariantInline = styled(AddToCart)`
  margin-left: auto;
`;

class AddToCartVariantInline extends PureComponent {
  state = {
    selectedProduct: null
  };
  onChange = productId => {
    const { variants } = this.props;
    const productVariant = _.find(
      variants,
      variant => _.get(variant, 'product.id') === _.toNumber(productId)
    );
    const product = _.get(productVariant, 'product', {});
    if (!_.isEmpty(product)) {
      this.setState({
        selectedProduct: { ...{ isOrderable: true }, ...product }
      });
    } else {
      this.setState({
        selectedProduct: null
      });
    }
  };
  render() {
    const { selectedProduct } = this.state;
    const { t, productVariantsWithValues } = this.props;
    return (
      <BlockFlex
        flexConfig={{ flexDirection: 'column' }}
        customStyle={'width:100%;'}
      >
        <SelectCustom
          customStyleWrapper={{
            height: '44px',
            marginRight: '5px',
            width: 'auto'
          }}
          customStyle={{
            height: '44px'
          }}
          name="productVariant"
          options={[].concat(
            [{ label: capfl(t('choose variant')), value: 0 }],
            _.map(productVariantsWithValues, item => {
              const { values, productId } = item;
              return {
                label: _.map(values, item => {
                  return item.name + ' - ' + item.value;
                }).join(', '),
                value: productId
              };
            })
          )}
          onChange={this.onChange}
        />
        {selectedProduct ? (
          <BlockFlex
            flexConfig={{
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
            marginConfig={{ top: true }}
          >
            <S.AddToCartVariantInlinePrice
              isB2B={true}
              className="product-card__price-inline"
              finalPriceData={selectedProduct.finalPriceData}
              useFormatPrice={false}
              customStyle={{
                finalPrice: {
                  fontSize: '1.4em'
                }
              }}
            />
            <S.AddToCartVariantInline
              title={'add to cart'}
              t={t}
              product={selectedProduct}
            />
          </BlockFlex>
        ) : (
          ''
        )}
      </BlockFlex>
    );
  }
}

const ProductCardVariant1 = ({
  t,
  theme,
  className,
  data,
  imagePlaceholder,
  imageWidth,
  imageHeight,
  isBig = false,
  showDiscount = false,
  showBrand = true,
  showLabels = true,
  showDescription = true,
  lazyload = true,
  hasBoxShadow = true,
  layout,
  isLocked = false,
  isSmallScreen = false,
  onClickCallback,
  ...rest
}) => {
  const classNames = ['product-card'];
  const {
    id,
    urlTitle,
    name,
    photo,
    rating,
    brand,
    availability,
    storeStatusAvailable,
    storeStatusUnavailable,
    // available,
    finalPrice,
    finalPriceWithoutDPH,
    priceData,
    finalPriceData,
    price,
    measureUnit,
    variants,
    shortDescription,
    discount
  } = data;

  if (className) {
    classNames.push(className);
  }

  imageWidth = Math.floor(imageWidth ? imageWidth : 200);
  imageHeight = Math.floor(imageHeight ? imageHeight : (imageWidth / 16) * 9);
  let productVariantsWithValues = [];
  if (!_.isEmpty(variants)) {
    productVariantsWithValues = _.map(variants, variant => {
      const productId = _.get(variant, 'variantProductId');
      const options = _.get(variant, 'options');
      let values = {};
      _.each(options, option => {
        const { variantOptionId, variantOptionName, value } = option;
        values[variantOptionId] = { name: variantOptionName, value };
      });
      return { productId, values };
    });
  }
  if (layout == LISTING_LAYOUT_ROWS) {
    return (
      <BlockBordered
        className={classNames.join(' ')}
        marginConfig={{}}
        borderConfig={{ bottom: true }}
        key={'product-card-variant1-product-' + id}
      >
        <Row>
          <Column size={{ xs: 4, md: 2, lg: 1 }}>
            <S.ImageLink urlTitle={urlTitle} onClick={onClickCallback}>
              {showLabels ? <Sticker product={data} /> : ''}
              {(photo || imagePlaceholder) && (
                <S.Image
                  className="product-card__img"
                  updateTime={_.get(photo, 'updateTime')}
                  path={_.get(photo, 'path', getImagePath(imagePlaceholder))}
                  size={`80x80.jpg`}
                  alt={_.get(photo, 'title', name)}
                  lazyload={lazyload}
                />
              )}
            </S.ImageLink>
          </Column>
          <Column
            size={{ xs: 8, md: !isLocked ? 5 : 10, lg: !isLocked ? 6 : 11 }}
          >
              <S.ProductName route="product" inline={true} params={{ id: urlTitle }} onClick={onClickCallback}>
                {name}
              </S.ProductName>
              <BlockFlex
                marginConfig={{}}
                padding={'5px 0 0 0;'}
                flexConfig={{ flexDirection: 'start' }}
              >
                <strong>{_.get(brand, 'name')}</strong>
                <Availability
                  availability={availability}
                  text={
                    availability ? storeStatusAvailable : storeStatusUnavailable
                  }
                />
              </BlockFlex>
          </Column>
          {!isLocked ? (
            <Column size={{ xs: 12, md: 5, lg: 5 }}>
              <S.AddToCartWrapperInline>
                {!_.isEmpty(productVariantsWithValues) ? (
                  <AddToCartVariantInline
                    t={t}
                    variants={variants}
                    productVariantsWithValues={productVariantsWithValues}
                  />
                ) : (
                  <S.InlineAddToCart>
                    <S.InlinePrice
                      isB2B={true}
                      showDiscount={showDiscount}
                      useFormatPrice={false}
                      className="product-card__price"
                      finalPrice={_.get(finalPriceData, 'priceFormatted' ,finalPrice)}
                      finalPriceWithoutDPH={finalPriceWithoutDPH}
                      priceData={priceData}
                      finalPriceData={finalPriceData}
                      discount={discount}
                      measureUnit={measureUnit}
                      customStyle={{
                        finalPrice: {
                          fontSize: '1.4em'
                        }
                      }}
                    />
                    <AddToCart title={'add to cart'} t={t} product={data} />
                  </S.InlineAddToCart>
                )}
              </S.AddToCartWrapperInline>
            </Column>
          ) : null}
        </Row>
      </BlockBordered>
    );
  }
  return (
    <S.ProductCard className={classNames.join(' ')} hasBoxShadow={hasBoxShadow} key={'product-card-variant1-product-' + id}>
      <S.ProductCardInner>
        <S.ImageWrapper>
          <S.ImageLink urlTitle={urlTitle} onClick={onClickCallback}>
            {(photo || imagePlaceholder) && (
              <S.Image
                className="product-card__img"
                path={_.get(photo, 'path', getImagePath(imagePlaceholder))}
                size={`${imageWidth}x${imageHeight}.jpg`}
                updateTime={_.get(photo, 'updateTime')}
                alt={_.get(photo, 'title', name)}
                lazyload={lazyload}
              />
            )}
            {showLabels ? <Sticker product={data} /> : ''}
          </S.ImageLink>
          <S.UserActions>
            <AddToCompare
              t={t}
              productId={id}
              title={false}
              customStyle={ActionStyles(theme)}
            />
            <AddToWishList
              t={t}
              productId={id}
              title={false}
              customStyle={ActionStyles(theme)}
            />
          </S.UserActions>
        </S.ImageWrapper>
        <S.Top>
          <S.BrandWithRating>
            {showBrand && (
              <S.Brand className="product-card__brand">{_.get(brand, 'name')}</S.Brand>
            )}
            <Rating rating={rating} />
          </S.BrandWithRating>
          <S.ProductName
            className="product-card__name"
            nameLength={name.length}
            urlTitle={urlTitle}
            onClick={onClickCallback}
          >
            {name}
          </S.ProductName>
          <S.Availability>
            <Availability
              availability={availability}
              text={
                availability ? storeStatusAvailable : storeStatusUnavailable
              }
            />
          </S.Availability>
          {!isLocked && showDescription && (
            <StyledShortDesc>
              <div
                dangerouslySetInnerHTML={{
                  __html: shortDescription
                }}
              ></div>
            </StyledShortDesc>
          )}

          {!isLocked ? (
            <Fragment>
              {!_.isEmpty(productVariantsWithValues) ? (
                <S.AddToCartWrapper>
                  <AddToCartVariant
                    finalPriceData={finalPriceData}
                    priceData={priceData}
                    useFormatPrice={false}
                    t={t}
                    variants={variants}
                    productVariantsWithValues={productVariantsWithValues}
                  />
                </S.AddToCartWrapper>
              ) : (
                <S.Between>
                  <Price
                    showDiscount={showDiscount}
                    className="product-card__price"
                    useFormatPrice={false}
                    discount={discount}
                    measureUnit={measureUnit}
                    finalPriceData={finalPriceData}
                    priceData={priceData}
                  />
                  <AddToCart
                    title={isSmallScreen ? 'add to cart' : ''}
                    t={t}
                    product={data}
                    buttonSize={'small'}
                    fullWidth={isSmallScreen ? true : false}
                  />
                </S.Between>
              )}
            </Fragment>
          ) : null}
        </S.Top>
      </S.ProductCardInner>
    </S.ProductCard>
  );
};

export default ProductCardVariant1;
