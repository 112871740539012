import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { sprintf } from 'sprintf-js';

import { useWebInstance } from 'common/hooks';

import Image from '../styleguide/Image';

const WebInstanceImage = ({
  src,
  alt,
  description,
  className,
  path: oPath = '',
  srcParts = [],
  size = 'original.jpg',
  srcSet = '',
  lazyload = true,
  lazyloadPlaceholder,
  loadRetina = true,
  useNextGen = true,
  staticImage = false,
  sizes,
  createTime = '',
  updateTime = ''
}) => {
  const webInstance = useWebInstance();
  const { domain } = webInstance;
  if (!_.isEmpty(domain)) {
    let finalSrc = null;
    const altValue = alt ? alt : description ? description : undefined;
    let srcPartsWithDomain = [];
    if (!_.isEmpty(src)) {
      finalSrc = sprintf(
        '%1$s/%2$s',
        _.trimEnd(domain, '/'),
        _.trimStart(src, '/')
      );
    } else {
      srcPartsWithDomain = _.concat([_.trimEnd(domain, '/')], srcParts);
    }
    return (
      <Image
        src={finalSrc}
        alt={altValue}
        className={className}
        path={oPath}
        srcParts={srcPartsWithDomain}
        size={size}
        lazyload={lazyload}
        lazyloadPlaceholder={lazyloadPlaceholder}
        loadRetina={loadRetina}
        srcSet={srcSet}
        useNextGen={useNextGen}
        staticImage={staticImage}
        sizes={sizes}
        createTime={createTime}
        updateTime={updateTime}
      />
    );
  }
  return null;
};

export default WebInstanceImage;
