import React from 'react';
import {connect} from 'react-redux';
import {isRatingEnabledSelector} from 'common/selectors/theme';
import ReactRating from 'react-rating';
import _ from 'lodash';

import Icon from './Icon';
import styled from 'styled-components';

const COLOR_YELLOW = '#FFC330';
const TOTAL_STARS = 5;

const StyledFullRatingStar = styled(Icon)`
  color: ${({ theme }) => _.get(theme, 'rating.fullStarColor', COLOR_YELLOW)};
`;

const StyledEmptyRatingStar = styled(Icon)`
  color: #aaa;
`;

const StyledRating = styled(ReactRating)`
  display: flex !important;
  & > span {
    line-height: 1;
    display: block !important;
  }
`;

const EmptyRatingStar = <StyledEmptyRatingStar className="nc-ic_star_border_24px" />;
const FullRatingStar = <StyledFullRatingStar className="nc-favourite-31" />;

const Rating = ({ rating = 0, theme, isBig = false, ...rest }) => {
  const className = 'rating';

  if (!_.get(rest, 'isEnabled', false)) {
    return null;
  }

  return (
    <StyledRating
      className={className}
      initialRating={rating / 100 * TOTAL_STARS}
      emptySymbol={EmptyRatingStar}
      fullSymbol={FullRatingStar}
      stop={TOTAL_STARS}
      style={{ fontSize: isBig ? '1.2em !important' : '1em !important' }}
      {...rest}
    />
  );
};

const makeMapStateToProps = () => {
  const isRatingEnabled = isRatingEnabledSelector();
  return (state, props) => {
    return {
      isEnabled: isRatingEnabled(state, props)
    };
  };
};

export default connect(makeMapStateToProps)(Rating);
