import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';

import ButtonOutline from 'common/components/styleguide/ButtonOutline';
import Icon from 'common/components/styleguide/Icon';
import TextIcon from 'common/components/styleguide/TextIcon';

import { ICON_COMPARE } from 'common/constants/IconConstants';
import { addProductToCompare } from 'common/actions/CompareActions';
import { isCompareEnabledSelector } from 'common/selectors/theme';
import { capfl } from 'common/utils/index';

const AddToCompare = ({
  t,
  productId,
  title = 'add to compare',
  iconClassName = ICON_COMPARE,
  dispatchAddProductToCompare = null,
  customStyle,
  linkType = 'primary',
  isEnabled = false,
  ...rest
}) => {
  if (!isEnabled) {
    return null;
  }
  return (
    <ButtonOutline
      href="#"
      component={'a'}
      buttonType={'info'}
      buttonSize={'small'}
      margin={'2px'}
      enableBackground={true}
      onClick={e => {
        e.preventDefault();
        dispatchAddProductToCompare(productId);
      }}
      customStyle={_.get(customStyle, 'link', undefined)}
      linkType={linkType}
      {...rest}
    >
      {title ? (
        <TextIcon iconClassName={iconClassName}>
          {capfl(t(title))}
        </TextIcon>
      ) : (
        <Icon className={iconClassName} />
      )}
    </ButtonOutline>
  );
};

const makeMapStateToProps = () => {
  const isCompareEnabled = isCompareEnabledSelector();
  const mapStateToProps = (state, props) => {
    return {
      isEnabled: isCompareEnabled(state, props)
    };
  };
  return mapStateToProps;
};

const mapDispatchToProps = dispatch => {
  return {
    dispatchAddProductToCompare: productId => {
      dispatch(addProductToCompare(productId));
    }
  };
};

AddToCompare.propTypes = {
  productId: PropTypes.number.isRequired
};

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(AddToCompare);
