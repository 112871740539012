import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';

import TextIcon from 'common/components/styleguide/TextIcon';

const S = {};

S.Available = styled(TextIcon)`
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
  color: ${({ theme }) => _.get(theme, 'colors.success')};
`;

S.UnAvailable = styled(TextIcon)`
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
  color: ${({ theme }) => _.get(theme, 'colors.danger')};
`;

const Availability = ({
  availability,
  text,
  iconAvailable = null,
  iconUnavailable = null,
}) => {
    if (availability) {
        return (
          <S.Available iconClassName={iconAvailable ? null : 'nc-check-simple-2'}>
            {iconAvailable ? iconAvailable : null} {text}
          </S.Available>
        );
    }
    return (
      <S.UnAvailable iconClassName={iconUnavailable ? null : 'nc-simple-remove'}>
        {iconUnavailable ? iconUnavailable : null} {text}
      </S.UnAvailable>
    );
};

export default withTranslation()(Availability);
