import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {
  isAddToCartEnabledSelector,
  isProductCardAddToCartEnabledSelector,
  reactiveTemplateConfigSelector
} from 'common/selectors/theme';
import { isUserLoggedSelector } from 'common/selectors/user';
import { getIsB2BLockedSelector } from 'common/selectors';

import { addToCart } from 'common/actions/CartActions';

import loadable from '@loadable/component';
const AddToCartButtonVariant = loadable(props =>
  import(`./${props.blockVariant}`)
);

class AddToCart extends PureComponent {
  constructor(props) {
    super(props);
    this.onAddToCartClick = this.onAddToCartClick.bind(this);
  }
  onAddToCartClick = e => {
    e.preventDefault();
    const { product, count = 1, dispatchAddToCart } = this.props;
    const firstStore = _.get(product, 'store[0]', null);
    const productId = _.get(product, 'id', null);
    if (productId && !_.isEmpty(firstStore) && firstStore.count > 0) {
      dispatchAddToCart(productId, firstStore.id, count);
    }
  };
  render() {
    const { t, isEnabled, blockVariant, ...rest } = this.props;
    if (!isEnabled) {
      return null;
    }
    return (
      <AddToCartButtonVariant
        blockVariant={blockVariant}
        onAddToCartClick={this.onAddToCartClick}
        t={t}
        {...rest}
      />
    );
  }
}

const makeMapStateToProps = () => {
  const isAddToCartEnabled = isAddToCartEnabledSelector();
  const isProductCardAddToCartEnabled = isProductCardAddToCartEnabledSelector();
  const isUserLogged = isUserLoggedSelector();
  const isB2BLockedSelector = getIsB2BLockedSelector();
  const reactiveTemplateConfig = reactiveTemplateConfigSelector(
    'AddToCartButtonVariant'
  );
  return (state, props) => {
    return {
      isEnabled:
        isAddToCartEnabled(state, props) &&
        isProductCardAddToCartEnabled(state, props) &&
        !(isB2BLockedSelector(state, props) && !isUserLogged(state, props)),
      blockVariant: reactiveTemplateConfig(state, props)
    };
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatchAddToCart: (productId, sizeId, count) => {
      dispatch(addToCart({ productId, sizeId, count }));
    }
  };
};

AddToCart.propTypes = {
  product: PropTypes.object.isRequired
};

export default connect(makeMapStateToProps, mapDispatchToProps)(AddToCart);
