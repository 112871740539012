import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import {
  getCurrencySymbolSelector,
  getIsB2BSelector,
  getIsB2BLockedSelector
} from 'common/selectors';
import { isUserLoggedSelector } from 'common/selectors/user';
import { isMeasureUnitEnabledSelector } from 'common/selectors/theme';
import { formatDiscount, formatPrice } from 'common/utils';

import Price from 'common/components/styleguide/Price';

const WebinstancePrice = ({
  t,
  theme,
  showDiscount = false,
  showWithoutVat = true,
  showPriceOld = true,
  showPrimaryText = false,
  finalPrice,
  finalPriceWithoutDPH,
  price,
  discount,
  currencySymbol,
  className,
  measureUnit,
  customStyle,
  isB2B = false,
  isB2BLocked = false,
  isMeasureUnitEnabled = false,
  isLogged = false,
  useFormatPrice = true,
  useFormatDiscount = true,
  useCurrencySymbol = true,
  soloFinalPrice = false,
  suffixOnNewLine = false,
  soloPriceSuffix = '',
  isInline = false,
  withoutVatPrefix = '',
  withoutVatSuffix = '',
  priceData = null,
  finalPriceData = null
}) => {
  if (!isLogged && isB2BLocked) {
    return null;
  }
  return (
    <Price
      isB2B={isB2B}
      className={className}
      theme={theme}
      showDiscount={showDiscount}
      showWithoutVat={showWithoutVat}
      showPriceOld={showPriceOld}
      showPrimaryText={showPrimaryText}
      finalPrice={useFormatPrice ? formatPrice(finalPrice) : finalPrice}
      finalPriceWithoutDPH={useFormatPrice ? formatPrice(finalPriceWithoutDPH) : finalPriceWithoutDPH}
      soloFinalPrice={soloFinalPrice}
      suffixOnNewLine={suffixOnNewLine}
      soloPriceSuffix={soloPriceSuffix}
      price={useFormatPrice ? formatPrice(price) : price}
      discount={discount ? (useFormatDiscount ? formatDiscount(discount) : discount) : null}
      currencySymbol={useCurrencySymbol ? currencySymbol : ''}
      withoutVatText={t('without VAT')}
      withVatText={t('with VAT')}
      measureUnit={isMeasureUnitEnabled && measureUnit ? measureUnit : null}
      customStyle={customStyle}
      isInline={isInline}
      withoutVatPrefix={withoutVatPrefix}
      withoutVatSuffix={withoutVatSuffix}
      priceData={priceData}
      finalPriceData={finalPriceData}
    />
  );
};

const makeMapStateToProps = () => {
  const getWebInstanceCurrencySymbol = getCurrencySymbolSelector();
  const isMeasureUnitEnabled = isMeasureUnitEnabledSelector();
  const isB2BSelector = getIsB2BSelector();
  const isB2BLockedSelector = getIsB2BLockedSelector();
  const isUserLogged = isUserLoggedSelector();
  return (state, props) => {
    return {
      currencySymbol: getWebInstanceCurrencySymbol(state, props),
      isMeasureUnitEnabled: isMeasureUnitEnabled(state, props),
      isB2B: isB2BSelector(state, props),
      isB2BLocked: isB2BLockedSelector(state, props),
      isLogged: isUserLogged(state, props)
    };
  };
};

const WebinstancePriceConnected = connect(makeMapStateToProps)(
  WebinstancePrice
);

export default withTranslation()(WebinstancePriceConnected);
