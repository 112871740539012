import React, { PureComponent } from 'react';
import styled from 'styled-components';
import LazyLoad from 'react-lazyload';
import _ from 'lodash';

import { generateSrcSet } from 'common/utils';
import { GENERATED_IMAGE_DENSITIES } from 'common/constants/SiteConstants';

const S = {};
S.Img = styled.img`
  display: block;
`;

const Picture = ({ finalSrc, alt, className, sizes, sourcesElements }) => {
  if (!_.isEmpty(sourcesElements)) {
    return (
      <picture className={className}>
        {_.map(sourcesElements, e => e)}
        <S.Img src={finalSrc} alt={alt} sizes={sizes} />
      </picture>
    );
  }
  return <S.Img src={finalSrc} alt={alt} className={className} sizes={sizes} />;
};


class Image extends PureComponent {
  render() {
    let {
      src,
      alt,
      className,
      srcSet,
      path: oPath = '',
      srcParts = [],
      size = 'original.jpg',
      lazyloadPlaceholder,
      lazyload = true,
      useNextGen = false,
      staticImage = false,
      sizes,
      createTime = '',
      updateTime = ''
    } = this.props;
    const updateTimeTimestamp = Date.parse(updateTime);
    const timestampSuffix = updateTimeTimestamp ? '?v=' + updateTimeTimestamp : ''
    let finalSrc = src;
    let srcSetStr;
    let sourcesElements;
    let finalSrcParts = [];
    let hasUrlTitle = _.endsWith(oPath, '_');
    if (_.isEmpty(finalSrc)) {
      finalSrcParts = [].concat(srcParts);
      if (oPath) {
        finalSrcParts.push(_.trimStart(oPath, '/'));
      }
    }
    if (staticImage) {
      return <img src={finalSrc + timestampSuffix} alt={alt} className={className} />;
    }
    if (_.isEmpty(srcSet)) {
      srcSet = {
        '1px': size
      };
    }
    const srsSetArr = [];
    const nextGenSourcesArr = [];
    const sourcesArr = [];
    const lastKey = _.findLastKey(srcSet);
    let pathWithUrlTitle = '';
    _.each(srcSet, (imgSize, breakpoint) => {
      const finalSrcSetItemParts = [].concat(finalSrcParts);
      if (hasUrlTitle) {
        pathWithUrlTitle = _.last(finalSrcSetItemParts)
        finalSrcSetItemParts[_.lastIndexOf(finalSrcSetItemParts, pathWithUrlTitle)] = pathWithUrlTitle + _.trimStart(imgSize, '/');
      } else {
        finalSrcSetItemParts.push(_.trimStart(imgSize, '/'));
      }
      let finalSource = _.isEmpty(finalSrc)
        ? finalSrcSetItemParts.join('/')
        : finalSrc;
      finalSource += timestampSuffix;
      srsSetArr.push(`${finalSource} ${breakpoint}`);
      const sourceSrcSet = generateSrcSet(
        finalSource,
        GENERATED_IMAGE_DENSITIES
      );
      const finalSrcsetStr = sourceSrcSet.join(', ');
      const source =
        lastKey === breakpoint ? (
          <source key={finalSrcsetStr} srcSet={finalSrcsetStr} />
        ) : (
          <source
            key={finalSrcsetStr}
            media={'(max-width: ' + breakpoint + ')'}
            srcSet={finalSrcsetStr}
          />
        );
      sourcesArr.push(source);
      if (useNextGen) {
        let finalSourceWebp =
          finalSource.substr(0, _.lastIndexOf(finalSource, '.')) + '.webp';
        finalSourceWebp += timestampSuffix;
        const nexGenSourceSrcSet = generateSrcSet(
          finalSourceWebp,
          GENERATED_IMAGE_DENSITIES
        );
        const nextGenFinalSrcsetStr = nexGenSourceSrcSet.join(', ');
        const nextGenSource =
          lastKey === breakpoint ? (
            <source
              key={nextGenFinalSrcsetStr}
              type="image/webp"
              srcSet={nextGenFinalSrcsetStr}
            />
          ) : (
            <source
              key={nextGenFinalSrcsetStr}
              media={'(max-width: ' + breakpoint + ')'}
              type="image/webp"
              srcSet={nextGenFinalSrcsetStr}
            />
          );
        nextGenSourcesArr.push(nextGenSource);
      }
    });
    srcSetStr = srsSetArr.join(', ');
    sourcesElements = nextGenSourcesArr.concat(sourcesArr);

    if (hasUrlTitle) {
      pathWithUrlTitle = _.last(finalSrcParts)
      finalSrcParts[_.lastIndexOf(finalSrcParts, pathWithUrlTitle)] = pathWithUrlTitle + _.trimStart(size, '/');
    } else {
      finalSrcParts.push(_.trimStart(size, '/'));
    }
    finalSrc = finalSrcParts.join('/') + (updateTimeTimestamp ?  '?v=' + updateTimeTimestamp : '');
    if (lazyload) {
      return (
        <LazyLoad
          height={200}
          placeholder={lazyloadPlaceholder ? lazyloadPlaceholder : undefined}
          once
        >
          <Picture
            sourcesElements={sourcesElements}
            finalSrc={finalSrc}
            alt={alt}
            srcSetStr={srcSetStr}
            srcSet={srcSetStr}
            sizes={sizes}
            className={className}
          />
        </LazyLoad>
      );
    }
    return (
      <Picture
        sourcesElements={sourcesElements}
        finalSrc={finalSrc}
        alt={alt}
        srcSetStr={srcSetStr}
        srcSet={srcSetStr}
        sizes={sizes}
        className={className}
      />
    );
  }
}

export default Image;
